// import { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";

import Pascal from "./pascal/Home";
import Sad3 from "./sad/3/Home";
import Assoc from "./spring/Assoc";

function NotFound(props) {
    return (
        <>
            <div className="page">
                <h3>{props.match.params.what}?</h3>
                <a href="/">Ну не знаю.</a>
            </div>
        </>
    );
}

function App() {
    // const { pathname } = useLocation();

    /* useEffect(()=>{
        
        const toplevel = pathname.split("/")[1];
        const favicon = document.querySelector('[rel=icon]');
        let bodyClass = "", href = "/favicon2.ico";
        switch (toplevel) {
            case "icons": {
                bodyClass = "icons";
                break;
            }
            default: {
                bodyClass = "pascal";
            }
        }
        document.body.className = bodyClass;
        favicon.href = href;
    }, [pathname]); */

    return (
        <Switch>
            <Route
                exact
                path="/"
                component={() => {
                    window.location.href = "https://ein.romochka.lv";
                    return null;
                }}
            />
            {/* <Route exact path="/" component={ () => <Pascal /> } /> */}
            <Route exact path="/spring">
                <Assoc />
            </Route>
            {/* <Route exact path="/sad3">
              <Sad3 />
          </Route> */}
            <Route path="/:what" component={NotFound} />
        </Switch>
    );
}

export default App;
