import { animated, useSpring } from "react-spring";
import React, { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import "./Assoc.scss";

const Assoc = () => {
    const [data, setData] = useState();

    useEffect(() => {
        const fetchAssoc = async () => {
            try {
                const response = await fetch("/assets/assoc/assoc.json");
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                const json = await response.json();
                return json;
            } catch (err) {
                console.log(err);
            }
        };
        // setTimeout(() => {
        fetchAssoc().then((json) => setData(json));
        // }, 1000);
    }, []);

    if (!data) return <div>...</div>;

    return <Selector data={data} />;
};

const Selector = ({ data }) => {
    const [index, setIndex] = useState(0);

    const [spring, setSpring] = useState();

    useEffect(() => {
        setSpring({ array: data[index].value, config: data[index].config });
    }, [index, data]);

    const changeHandler = (i) => () => {
        setIndex(i);
    };

    return (
        <div className="selector">
            {data.map((record, i) => {
                const { name } = record;
                const id = name + i;
                return (
                    <label htmlFor={id} key={i}>
                        <input
                            onChange={changeHandler(i)}
                            checked={i === index}
                            type="radio"
                            name={name}
                            id={id}
                        />
                        {name}
                    </label>
                );
            })}
            {spring && <Animated source={spring} />}
        </div>
    );
};

const getLabel = (direction, array) => {
    const res = (direction
        ? `${array[0]} -> ${array[array.length - 1]}`
        : `${array[array.length - 1]} -> ${array[0]}`);

    // console.log(res);
    return res;
}


const Animated = React.memo(({ source: raw }) => {

    // const { array, config } = source;
    
    // console.log(source.array);
    const [source, setSource] = useState(raw);
    const prevSource = usePrevious(source);

    const [array, setArray] = useState(source.array);
    const prevArray = usePrevious(array);
    const [config, setConfig] = useState(source.config);

    const [direction, setDirection] = useState(null);
    const prevDirection = usePrevious(direction);

    const [label, setLabel] = useState(null);

    const [spring, setSpring, stop] = useSpring(() => ({
        to: { n: 0 },
        config,
        /* onRest: () => {
            setLabel(getLabel(direction, array));
            // setDirection(!direction);
        }, */
    }));

    const start = () => {
        setDirection(!direction);
    };

    /* useEffect(()=>{
        console.log("Animate render");
    }); */

    useEffect(() => {
        if (prevSource !== source) {
            // console.log("source changed");
            stop();
            setDirection(null);
            setLabel(getLabel(true, source.array));
            setArray(source.array);
            setConfig(source.config);
            setSpring({
                to: { n: 0 },
                config: source.config,
                onRest: () => {  },
                immediate: true,
            });
        } else {
            // console.log("source not changed");
            if (prevDirection !== direction) {
                // console.log("direction changed from", prevDirection, "to", direction);
                // if (prevDirection !==)
                setSpring({
                    to: { n: direction ? source.array.length-1 : 0 },
                    config: source.config,
                    onRest: () => { setLabel(getLabel(!direction, source.array)) },
                    immediate: false,

                });
            } else {
                // console.log("direction not changed and equals", direction);
            }
        }
    });

    useEffect(()=>{
        // console.log("RAW!");
        setSource(raw);
    }, [raw]);


    return (
        <div>
            <animated.div className="assoc">
                {spring.n.interpolate((n) => {
                    const int = Math.round(n);
                    // console.log(int);
                    return array[int];
                })}
            </animated.div>
            <button onClick={start}>{label}</button>
        </div>
    );
});

export default Assoc;
