
/*eslint no-extend-native: ["error", { "exceptions": ["Array", "Object"] }]*/

export function cl() {
    const classList = Array.prototype.slice.call(arguments)
    .filter(c=>c)
    .map(c => {
        if (getType(c) === "object") {
            return Object.keys(c).map(key => (
                c[key] === true
                ? key
                : false
            )).filter(c=>c);
        }
        return c
    })
    .flatten();

    // console.log(classList);

    return classList.length > 0 ? classList.filter(c=>c).join(" ") : null;
};

export function generateId() {
  function S4() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  }
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export function objectsEqual(x, y) {
    // https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
    if ( x === y ) return true;
    // if both x and y are null or undefined and exactly the same

    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
    // if they are not strictly equal, they both need to be Objects

    if ( x.constructor !== y.constructor ) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for ( var p in x ) {
        if ( ! x.hasOwnProperty( p ) ) continue;
        // other properties were tested using x.constructor === y.constructor

        if ( ! y.hasOwnProperty( p ) ) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if ( x[ p ] === y[ p ] ) continue;
        // if they have the same strict value or identity then they are equal

        if ( typeof( x[ p ] ) !== "object" ) return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if ( ! objectsEqual( x[ p ],  y[ p ] ) ) return false;
        // Objects and Arrays must be tested recursively
    }

    for ( p in y ) {
        if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
        // allows x[ p ] to be set to undefined
    }
    return true;

}

export const deepCopy = (inObject) => {
    let outObject, value, key
    // https://medium.com/javascript-in-plain-english/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089

    if (typeof inObject !== "object" || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }
  
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}
  
    for (key in inObject) {
      value = inObject[key]
  
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = deepCopy(value)
    }
  
    return outObject
}

export function unique(arr) {
  return arr.filter(function (c, index, self) { return self.indexOf(c) === index });
}

if (!Array.prototype.remove) Object.defineProperty(Array.prototype, "remove", {
  value: function (obj) {
      return this.filter(elem => !objectsEqual(obj, elem));
  }
});

if (!Array.prototype.unique) Object.defineProperty(Array.prototype, "unique", {
  value: function () {
      return this.filter(function (c, index, self) { return self.indexOf(c) === index });
  }
});

if (!Array.prototype.uniqueByKey) Object.defineProperty(Array.prototype, "uniqueByKey", {
  value: function (key) {
      // https://dev.to/saigowthamr/how-to-remove-duplicate-objects-from-an-array-javascript-48ok
      return this
          .map(e => e[key])
          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)
          // eliminate the dead keys & store unique objects
          .filter(e => this[e]).map(e => this[e]);
  }
});

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomValue(arr) {
  const sum = arr.reduce((acc, e) => acc + e.chance, 0);
  //console.log(sum);
  const random = getRandomInt(0, sum-1);
  //console.log(random);
  let acc = 0;

  for (let i=0; i<arr.length; i++) {
      const min = acc;
      const max = arr[i].chance + acc - 1;
      //console.log(`for ${arr[i].str}: ${min}-${max}`);
      acc += arr[i].chance;
      if (random >= min && random <=max) return arr[i].value;
  }
  return null;
}

export function getType(obj) {
  return /^\[object (\w+)]$/.exec(Object.prototype.toString.call(obj))[1].toLowerCase();
}

export function aiove(val, inc) {
  return Array.isArray(val) ? val.indexOf(inc) !== -1 : val === inc;
}

export function aov(obj, func) {
  if (Array.isArray(obj)) {
      let l = obj.length, x;
      for (let i = 0; i < l; i++) {
          x = func(obj[i], i);
          if (x===true) break;
      }
  } else {
      func(obj);
  }
}

export const arraysAndIndexesEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  for (let i=0, l=arr1.length; i<l; i++) {
    if (!objectsEqual(arr1[i], arr2[i])) return false;
  }

  return true;
}

export function camelCase(str) {
  const attrs = {
      "class": "className",
      "rowspan": "rowSpan",
      "colspan": "colSpan",
      "srcset": "srcSet",
      "strokewidth": "strokeWidth"
  };
  if (attrs.hasOwnProperty(str)) return attrs[str];
  // https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case/37041217
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

if (!Array.prototype.flatten) Object.defineProperty(Array.prototype, "flatten", {
  value: function(result = []) {
      for (let i = 0, length = this.length; i < length; i++) {
          const value = this[i];
          if (Array.isArray(value)) {
              value.flatten(result);
          } else {
              result.push(value);
          }
      }
      return result;
  }
});

if (!Array.prototype.findLast) Object.defineProperty(Array.prototype, "findLast", {
  value: function(compare) {
      for (let i = this.length - 1; i >= 0; i--) {
          if (compare(this[i])) return this[i];
      }
      return;
  }
});
